<template>
  <div :class="'iq-sidebar  sidebar-default ' + variant">
    <div
      class="iq-sidebar-logo d-flex align-items-center justify-content-between"
    >
      <a href="/" class="header-logo">
        <img
          src="@/assets/images/favicon.png"
          class="img-fluid rounded-normal light-logo"
          alt="logo"
        />
        <img
          src="@/assets/images/logo-dark.png"
          class="img-fluid rounded-normal d-none sidebar-light-img"
          alt="logo"
        />
        <span>MySyara</span>
      </a>
      <div class="side-menu-bt-sidebar-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-light wrapper-menu"
          width="30"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="miniSidebar"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <ul id="iq-sidebar-toggle" class="side-menu">
          <li class="px-3 pt-3 pb-2">
            <span class="text-uppercase small font-weight-bold"
              >Application</span
            >
          </li>
          <!-- <li v-if='this.userDetails.jobProfile !== undefined ? this.userDetails.jobProfile.type === "REGION_MANAGER" : ""'
            :class="
              checkActive('layout.dashboard')
                ? 'sidebar-layout active'
                : 'sidebar-layout'
            "
          >
            <router-link :to="{ name: 'layout.dashboard' }" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg"  
                  fill="none"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24">
                  <path d="M12 2c-6.627 0-12 5.373-12 12 0 2.583.575 5.344 2.599 8h18.753c2.023-2.656 2.648-5.417 2.648-8 0-6.627-5.373-12-12-12zm-.758 2.14c.256-.019.51-.029.758-.029s.502.01.758.029v3.115c-.252-.028-.506-.042-.758-.042s-.506.014-.758.042v-3.115zm-5.764 7.978l-2.88-1.193c.158-.479.352-.948.582-1.399l2.879 1.192c-.247.444-.441.913-.581 1.4zm1.217-2.351l-2.203-2.203c.329-.383.688-.743 1.071-1.071l2.203 2.203c-.395.316-.754.675-1.071 1.071zm.793-4.569c.449-.231.919-.428 1.397-.586l1.205 2.874c-.486.142-.954.339-1.397.586l-1.205-2.874zm1.407 13.802c.019-1.151.658-2.15 1.603-2.672l1.502-7.041 1.502 7.041c.943.522 1.584 1.521 1.602 2.672h-6.209zm4.989-11.522l1.193-2.878c.479.156.948.352 1.4.581l-1.193 2.878c-.444-.246-.914-.44-1.4-.581zm2.349 1.218l2.203-2.203c.383.329.742.688 1.071 1.071l-2.203 2.203c-.316-.396-.675-.755-1.071-1.071zm2.259 3.32c-.147-.483-.35-.95-.603-1.39l2.86-1.238c.235.445.437.912.602 1.39l-2.859 1.238z"/>
                </svg>
              </i>
              <span class="ml-2">Dashboard</span>
            </router-link>
          </li> -->
          <!-- <li
            :class="
              checkActive('layout.customer') ||
              checkActive('customer.customerview') ||
              checkActive('customer.customeredit') ||
              checkActive('customer.customeradd')
                ? 'sidebar-layout active'
                : 'sidebar-layout'
            "
          >
            <router-link :to="{ name: 'layout.customer' }" class="svg-icon">
              <i class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </i>
              <span class="ml-2">Customers</span>
            </router-link>
          </li> -->

          <li
            :class="
              checkActive('layout.washsubscription') ||
              checkActive('washsubscription.washsubscriptionview') ||
              checkActive('washsubscription.washsubscriptionedit') ||
              checkActive('washsubscription.washsubscriptionadd')
                ? 'sidebar-layout active'
                : 'sidebar-layout'
            "
          >
            <router-link :to="{ name: 'layout.washsubscription' }" class="svg-icon">
              <i class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </i> 
              <span class="ml-2">Wash Subscriptions</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { core } from "@/config/pluginInit";
export default {
  name: "SidebarStyle",
  props: {
    items: Array,
    variant: { type: String },
  },
  data() {
    return {
      userDetails:[],
      homeurl: "",
      dashboards: [
        "layout.dashboard1",
        "layout.dashboard2",
        "layout.dashboard3",
        "layout.customer",
        "layout.product",
        "layout.order",
        "layout.calendar",
      ],
      app: ["app.chat", "app.todo"],
      charts: [
        "chart.amchart",
        "chart.apexchart",
        "chart.highchart",
        "chart.morrischart",
      ],
      user: ["app.user-profile", "app.user-add", "app.user-list"],
      ui: [
        "Ui.avatars",
        "Ui.alerts",
        "Ui.badges",
        "Ui.breadcrumbs",
        "Ui.buttons",
        "Ui.button-groups",
        "Ui.boxshadows",
        "Ui.colors",
        "Ui.cards",
        "Ui.carousels",
        "Ui.grids",
        "Ui.helper-classes",
        "Ui.images",
        "Ui.list-groups",
        "Ui.media-objects",
        "Ui.modals",
        "Ui.notifications",
        "Ui.paginations",
        "Ui.popovers",
        "Ui.progressbars",
        "Ui.typographys",
        "Ui.tabs",
        "Ui.tooltips",
        "Ui.Embed-videos",
      ],
      auth: [
        "auth.login",
        "auth.register",
        "auth.recover-password",
        "auth.confirm-mail",
        "auth.lock-screen",
      ],
      pricing: ["price.pay", "price.pay2"],
      icon: [
        "icon.dripicon",
        "icon.fontawsome",
        "icon.lineawsome",
        "icon.remixicon",
      ],
      error: ["error.404", "error.500"],
      formControls: [
        "controls.form-checkbox",
        "controls.form-layout",
        "controls.form-input",
        "controls.form-radio",
        "controls.form-switch",
        "controls.form-textarea",
        "controls.form-validation",
      ],
      formWidget: [
        "widget.form-datepicker",
        "widget.form-file-uploader",
        "widget.form-quill",
      ],
      table: ["table.basic-table", "table.data-table", "table.edit-table"],
      timeline: ["time.line", "time.line1"],
      extraPages: [
        "price.pay",
        "pages.maintenance",
        "pages.comingsoon",
        "pages.invoices",
        "pages.subscribers",
        "pages.faq",
        "pages.blank-page",
        "pages.icon",
      ],
      pages: [],
      checklistSvg: require("../../../../assets/images/svg/checklist.svg"),
    };
  },
  mounted() {
    core.SmoothScrollbar();
    if (localStorage.getItem("user-details") !== null) {
      this.userDetails = JSON.parse(localStorage.getItem('user-details'))
    }
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get("sidebar");
    if (sidebar !== null) {
      this.variant = "";
      switch (sidebar) {
        case "0":
          this.variant = "sidebar-dark";
          break;
        case "1":
          this.variant = "sidebar-light";
          break;
        default:
          this.variant = "";
          break;
      }
    }
  },
  destroyed() {
    core.SmoothScrollbar();
  },
  computed: {
    ...mapGetters({
      logo: "darklogo",
      sidelogo: "logo",
    }),
  },
  methods: {
    checkActive(route) {
      if (this.$route.name == route) {
        return true;
      }
      if (route.includes(this.$route.name)) {
        return true;
      }
    },
    miniSidebar() {
      core.triggerSet();
    },
    showCollapse(collapseId) {
      document.getElementById(collapseId).closest("li").classList.add("active");
    },
    hideCollapse() {
      const list = document
        .querySelector(".iq-sidebar-menu")
        .querySelectorAll(".submenu");
      Array.from(list, (elem) => {
        if (
          elem.closest(".active") !== null &&
          elem.closest(".active") !== undefined
        ) {
          elem.closest(".active").classList.remove("active");
        }
      });
    },
  },
};
</script>
<style scoped>
.iq-sidebar-menu .side-menu li li.active a {
  color: #fff;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 5px;
}


</style>

