<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="side-menu-bt-sidebar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="text-secondary wrapper-menu"
            width="30"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            @click="miniSidebar()"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </div>
        <div class="d-flex align-items-center">
          <!-- <ModeSwitch /> -->
          <b-navbar-toggle target="nav-collapse" class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="text-secondary"
              width="30"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <ul class="navbar-nav ml-auto navbar-list align-items-center">
              <li class="nav-item nav-icon dropdown" v-nav-toggle>
                <a
                  href="#"
                  class="nav-item nav-icon dropdown-toggle pr-0 search-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="mb-0 ml-2 user-name">{{
                    userDetails.firstName + " " + userDetails.lastName
                  }}</span>
                </a>
              </li>

              <li class="nav-item nav-icon dropdown" v-nav-toggle>
                <a
                  class="nav-item nav-icon dropdown-toggle pr-0 search-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    class="img-fluid avatar-rounded p-1"
                    alt="user"
                    :src="userIcon"
                  />
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <router-link
                    :to="{ name: 'auth.login' }"
                    @click.native="logOut()"
                  >
                    <li class="dropdown-item d-flex svg-icon border-top">
                      <svg
                        class="svg-icon mr-0 text-primary"
                        id="h-05-p"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      Logout
                    </li>
                  </router-link>
                </ul>
              </li>
            </ul>
          </b-collapse>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapGetters } from "vuex";

export default {
  name: "HeaderStyle1",

  mounted() {
    this.userDetails = JSON.parse(localStorage.getItem("user-details"));
  },
  computed: {
    ...mapGetters(["authStatus", "user"]),
  },

  methods: {
    miniSidebar() {
      core.triggerSet();
    },
    logOut: function () {
      this.$store.dispatch("logOut").then(() => {
        this.$router.push("/auth/sign-in");
      });
    },
  },
  data() {
    return {
      userDetails: [],
      userIcon: require("../../../../assets/images/svg/user.svg"),
    };
  },
};
</script>
<style>
.b-toast-danger.b-toast-solid .toast.toast-box {
  padding: 10px;
  border-radius: 3px;
}
</style>
